@import "antd/dist/antd.less";

@primary-color: #01579b;
@secondary-color: #ec407a;

html,
body,
#app {
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

#loading-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        fill: transparent;
        stroke: #1a202c;
        stroke-width: 28px;
        animation: dash 2s ease infinite, rotate 6s linear infinite;
    }
}

.ant-avatar,
.ant-btn-icon-only,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.ant-avatar-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: inherit;
        height: inherit;
    }
}

.ant-collapse-arrow {
    vertical-align: 1.5px !important;
}

@media screen and (max-width: 640px) {
    .ant-form-item-required::before {
        right: -1em;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(227, 235, 245, 0.8);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:active {
    background-color: rgba(217, 228, 243, 0.8);
}

@keyframes dash {
    0% {
        stroke-dasharray: 10, 950;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 850, 950;
        stroke-dashoffset: -250;
    }

    100% {
        stroke-dasharray: 850, 950;
        stroke-dashoffset: -930;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
